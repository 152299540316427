<template>
  <!-- :field-history="fieldHistory" -->

  <YoTable
    ref-specify="FormApproval"
    :api-endpoint="apiEndpoint"
    title-tbl="this.$t('Dashboards') + ' Form Type 1 (T1)'"
    sub-title-tbl="this.$t('Land Vehicles')"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :disable-edit="disableEdit"
    :multiple-header="multipleHeader"
    :force-disable-crud="true"
    enable-create-form="-"
    placeholder-search="-"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/components/YoSimpleTable.vue'

export default {
  components: {
    YoTable
  },
  data() {
    return {
      autoRefresh: false,
      apiEndpoint: 'visitor_planning',
      titleTbl: 'DATA MASTER',
      disableEdit: true,
      multipleHeader: {
        startFrom: 1,
        content: [
          {
            value: 'Planning',
            span: '2',
            align: 'text-center'
          },
          {
            value: 'Tamu',
            span: '3',
            align: 'text-center'
          }
        ],
        noEnd: false,
        endFrom: 5
      },
      fields: [
        {
          key: 'visitor_qrcode.visitor_public.visitor_cat.desc',
          label: 'Kunjungan Ke',
          sortable: true,
          placeholder: 'Sarana',
          input: { type: 'input-noarea' },
          searchModel: 'visitor_qrcode.visitor_public.visitor_cat.desc',
          search: {
            type: 'text'
          }
        },
        {
          key: 'ket',
          label: 'Ket. / Keperluan + Approval',
          sortable: true,
          // stickyColumn: true,
          placeholder: 'Nama Lengkap',
          input: { type: 'input-noarea' },
          searchModel: 'visitor_qrcode.visitor_public.full_name',
          search: {
            type: 'text'
          }
        },
        {
          key: 'visitor_qrcode.visitor_public.full_name',
          label: 'Full Name',
          sortable: true,
          placeholder: 'Nama Lengkap',
          input: { type: 'input-noarea' },
          searchModel: 'visitor_qrcode.visitor_public.full_name',
          search: {
            type: 'text'
          }
        },
        {
          key: 'visitor_qrcode.visitor_public.from_company',
          label: 'Asal Perusahaan / Instansi',
          sortable: true,
          placeholder: 'Asal Perusahaan / Instansi',
          input: { type: 'input-noarea' },
          searchModel: 'visitor_qrcode.visitor_public.from_company',
          search: {
            type: 'text'
          }
        },
        {
          key: 'visitor_qrcode.visitor_public.phone',
          label: 'Wa / Phone ( Clickable )',
          sortable: true,
          placeholder: 'Wa / Phone',
          input: { type: 'input-noarea' },
          searchModel: 'visitor_qrcode.visitor_public.phone',
          search: {
            type: 'text'
          }
        },
        {
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Created AT',
          searchModel: 'created_at',
          search: {
            type: 'text'
          },
          input: {
            type: 'date',
            format: 'dddd, D MMMM YYYY - HH:mm',
            disabled: true
          }
        }
      ]
    }
  },

  computed: {
    newDataTemplate() {
      return {}
    }
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.visitor_plannings
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    }
  }
}
</script>
